<template>
  <a-card class="key-highlight-card">
    <div v-if="!isInit" class="text-center pa-2">
      <a-skeleton active />
    </div>
    <a-tabs v-else v-model:activeKey="activeTab" @change="loadTab">
      <a-tab-pane v-for="(item, idx) in selectedCatList" :key="idx" class="highlight-list">
        <!-- <div class="top-row">
          <div class="highlight-title">
            <a-badge :color="getColor(item.key, idx)" :text="item.key" />
          </div>
        </div> -->
        <template #tab>
          <div class="highlight-title">
            <a-badge :color="getColor(item, idx)" :text="item" />
          </div>
        </template>
        <div>
          <div v-if="loading" class="loading-wrapper">
            <a-skeleton :loading="loading" active />
          </div>
          <div
            v-else-if="noData"
            class="no-result-wrapper text-center"
          >
            <NoResult></NoResult>
          </div>
          <template v-else>
            <div class="bottom-row" :class="{ 'bottom-row-mobile': isMobileScreen }">
              <div
                v-for="(data, index) in highlightCategoryData[idx]"
                :key="data.user.user_name"
                class="highlight-content-wrapper"
              >
                <div class="highlight-content">
                  <div>
                    <KeyHighlightItem
                      class="list-item"
                      :item="data"
                      :order-no="index + 1"
                      :show-category="true"
                      @click="drilldown(data)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </a-tab-pane>
    </a-tabs>
    <SeeMoreDialog />
  </a-card>
</template>

<script>
import { ref, computed, toRefs, watch, provide, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';
import KeyHighlightItem from '@/components/Message/KeyHighlightItem.vue';
import NoResult from '@/components/Error/NoResult.vue';
import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import _ from 'lodash';

export default {
  name: 'KeyHighlight',
  components: {
    NoResult,
    KeyHighlightItem,
    SeeMoreDialog,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filterResult: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const { filterResult } = toRefs(props);
    const isInit = ref(false);
    const loading = ref(true);
    const error = ref(false);

    const activeTab = ref(0);

    const subCategoryName = ref({});
    const highlightCategoryData = ref([]);
    const visibleCatList = computed(() => store.getters['config/visibleCatList']);
    const selectedCatList = computed(() => {
      if (filterResult.value) {
        const list = _.cloneDeep(filterResult.value.categoryList);
        return [
          'all',
          ...list.sort((a, b) => {
            if (a === 'others') {
              return 1;
            } else if (b === 'others') {
              return -1;
            } else {
              return 0;
            }
          }),
        ];
      }
      return [];
    });

    const noData = computed(() => {
      for (var idx = 0; idx < selectedCatList.value.length; idx++) {
        const noData1Item =
          !highlightCategoryData.value[idx] ||
          highlightCategoryData.value[idx].length === 0;
        if (!noData1Item) {
          return false;
        }
      }
      return true;
    });

    const getColor = (category, index) => {
      // const color = helper.getColor(brand.toLowerCase(), index);
      const { level } = filterResult.value;
      const color = helper.getColorByCategory(level, category, index);
      return color;
    };

    const drilldown = (item) => {
      store.dispatch('seemore/show', item);
    };

    watch(filterResult.value, () => {
      activeTab.value = 0;
      highlightCategoryData.value = [];
      loadTab(0);
    });

    const loadTab = async (tabIndex) => {
      console.log('LOADTAB', tabIndex);

      const { filterData, level: lvl, categoryList } = filterResult.value;
      if (!highlightCategoryData.value[tabIndex]) {
        loading.value = true;
        let overrideCategory = {};
        const level = parseInt(lvl, 10);
        if (categoryList) {
          const catLists = categoryList.sort((a, b) => {
            if (a === 'others') {
              return 1;
            } else if (b === 'others') {
              return -1;
            } else {
              return 0;
            }
          });
          if (tabIndex === 0) {
            // load all
          } else {
            let cat = catLists[tabIndex - 1];
            if (level === 0) {
              overrideCategory.category = [cat];
            } else {
              // Sort to just for sure
              overrideCategory.subCategory = JSON.parse(JSON.stringify(filterData.subCategory));
              overrideCategory.subCategory.sort((a, b) => a.level - b.level);
              overrideCategory.subCategory[Number(level) - 1] = {
                level: level,
                category: [cat],
              };
            }
          }
          const result = await api
            .getSearchData({
              ...filterData,
              ...overrideCategory,
              sort: {
                field: 'engagement_score',
                direction: 'desc',
              },
              paging: {
                page: 1,
                recordPerPage: 3,
              },
            })
            .catch(() => {
              error.value = true;
            });
          if (result && result.message && result.message.data) {
            subCategoryName.value = result.message.subCategoryName;
            highlightCategoryData.value[tabIndex] = result.message.data;
          }
        }
      }
      setTimeout(() => {
        loading.value = false;
        isInit.value = true;
      }, 500);
    };

    onMounted(() => {
      loadTab(0);
    });

    provide('onEditMessage', (e) => {
      helper.saveLocalMessage(e, highlightCategoryData.value[activeTab.value]);
    });

    provide('subCategoryName', subCategoryName);

    onBeforeUnmount(() => {
      store.dispatch('seemore/close');
    });

    return {
      isInit,
      loading,
      error,
      activeTab,
      drilldown,
      getColor,
      visibleCatList,
      selectedCatList,
      highlightCategoryData,
      loadTab,
      isMobileScreen,
      noData,
    };
  },
};
</script>

<style lang="scss">
.key-highlight-card {
  .ant-card-body {
    padding: 0px !important;
    min-height: 360px;
  }

  .w-100 {
    width: 100%;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin-right: 5px;
  }

  .highlight-title {
    // padding-left: 40px;
    text-transform: capitalize;
    .ant-badge-status-dot {
      top: 0px !important;
    }
    .ant-badge-status-text {
      font-size: 13px;
      color: #868eae;
    }
  }

  .highlight-list {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    padding: 20px;
    overflow: auto;
    &.row-bg-color {
      background: #f0f2f5;
    }

    // .top-row {
    //   margin-bottom: 0.7rem;

    // }

    .bottom-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      box-sizing: border-box;
      margin: 0px -10px;
      .highlight-content-wrapper {
        box-sizing: border-box;
        max-width: 33.33%;
        min-width: 300px;
        margin-bottom: 20px;
        padding: 0px 10px;
        .highlight-content {
          box-sizing: border-box;
          background: #ffffff;
          border-radius: 10px;
          box-shadow: 0px 5px 30px rgba(146, 153, 184, 0.149);
          height: 100%;
          padding: 20px 20px 0px;

          // Override
          .username-row {
            font-size: 13px;
          }
          .date-tag {
            font-size: 10px;
          }
        }
      }

      @media only screen and (max-width: 992px) {
        .highlight-content-wrapper {
          width: 100%;
        }
      }
    }

    .bottom-row-mobile {
      flex-direction: column;
      justify-content: center;
      .highlight-content-wrapper {
        max-width: 100%;
      }
    }

    .loading-wrapper {
      width: 100%;
    }
    .no-result-wrapper {
      min-height: 257px;
    }
  }
}
</style>
