<template>
  <a-card class="channel-overview no-body-padding" title="Channel">
    <div v-if="!isMobileScreen" class="source-selector">
      <a-checkbox-group
        v-model:value="selectedSource"
        :options="sourceOptions"
        @change="onSourceSelect"
      >
      </a-checkbox-group>
    </div>
    <div v-else class="source-selector-mobile">
      <div class="source-select-title">Channels</div>
      <a-select
        v-model:value="selectedSourceMobile"
        :getPopupContainer="(trigger) => trigger.parentNode"
        class="source-select"
        style="width: 100%"
        :options="sourceOptions"
        @change="onSourceSelect"
        placeholder="Select Source"
      >
        <template #option="{ value, label }">
          <div class="source-item" :aria-label="value">
            <img
              class="source-img"
              :src="require(`@/assets/images/source-icon/svg/${value}.svg`)"
              alt="logo"
            />
            &nbsp;&nbsp;{{ value === 'twitter' ? 'X (Twitter)' : label }}
          </div>
          <!-- <div v-if="value === 'twitter'" class="source-item" :aria-label="value">
            <img class="source-img" :src="require('@/assets/images/source-icon/svg/twitter.svg')" alt="logo" />
            &nbsp;&nbsp;{{ label }}
          </div>
          <div v-if="value === 'instagram'" class="source-item" :aria-label="value">
            <img class="source-img" :src="require('@/assets/images/source-icon/svg/instagram.svg')" alt="logo" />
            &nbsp;&nbsp;{{ label }}
          </div>
          <div v-if="value === 'pantip'" class="source-item" :aria-label="value">
            <img class="source-img" :src="require('@/assets/images/source-icon/svg/pantip.svg')" alt="logo" />
            &nbsp;&nbsp;{{ label }}
          </div>
          <div v-if="value === 'youtube'" class="source-item" :aria-label="value">
            <img class="source-img" :src="require('@/assets/images/source-icon/svg/youtube.svg')" alt="logo" />
            &nbsp;&nbsp;{{ label }}
          </div>
          <div v-if="value === 'blockdit'" class="source-item" :aria-label="value">
            <img class="source-img" :src="require('@/assets/images/source-icon/svg/blockdit.svg')" alt="logo" />
            &nbsp;&nbsp;{{ label }}
          </div>
          <div v-if="value === 'tiktok'" class="source-item" :aria-label="value">
            <img class="source-img" :src="require('@/assets/images/source-icon/svg/tiktok.svg')" alt="logo" />
            &nbsp;&nbsp;{{ label }}
          </div> -->
          <!-- <div
            v-if="value === 'website'"
            class="source-item"
            :aria-label="value"
          >
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/website.svg')"
              alt="logo"
            />
            &nbsp;&nbsp;{{ label }}
          </div> -->
        </template>
      </a-select>
    </div>

    <div v-if="chartLoading" class="load-wrapper">
      <a-skeleton active />
    </div>

    <div
      v-show="
        !chartLoading &&
        (finalEngageSeries.length > 0 || finalCountSeries.length > 0)
      "
      class="chart-wrapper"
    >
      <v-chart
        ref="coChart"
        class="chart"
        :option="chartOptions"
        :autoresize="true"
        @click="onChartClick"
      />
    </div>

    <div
      v-if="
        !chartLoading &&
        (finalEngageSeries.length === 0 || finalCountSeries.length === 0)
      "
      class="no-data-wrapper"
    >
      <NoResult></NoResult>
    </div>

    <div
      v-if="!chartLoading"
      class="pa-3"
      :class="{ 'text-right': !isMobileScreen }"
    >
      <a @click="changeStateTable" :class="{ disabled: noData }">
        {{ showTable ? 'Hide ' : 'Show ' }}SOV
      </a>
    </div>

    <!-- TABLE SOV -->
    <div
      v-if="showTable && channelData && channelData.length"
      class="channel-table-wrapper"
    >
      <div class="channel-table">
        <a-table
          :data-source="channelData"
          :columns="channelColumns"
          :pagination="false"
          :scroll="{ x: 'calc(700px + 50%)', y: 300 }"
        >
          <template #customCategoryTitle="">
            <span class="category-title">Share of Voice (SOV)</span>
          </template>
          <template #category="{ text, index }">
            <div class="badge-wrapper">
              <a-badge :color="getColor(text, index)" />
              <span class="category-text"> {{ text }} </span>
            </div>
          </template>

          <template #customFacebookTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/facebook.svg')"
              alt="logo"
            />
          </template>

          <template #customTwitterTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/twitter.svg')"
              alt="logo"
            />
          </template>
          <template #customInstagramTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/instagram.svg')"
              alt="logo"
            />
          </template>
          <template #customYoutubeTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/youtube.svg')"
              alt="logo"
            />
          </template>
          <template #customPantipTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/pantip.svg')"
              alt="logo"
            />
          </template>
          <template #customBlockditTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/blockdit.svg')"
              alt="logo"
            />
          </template>
          <template #customTiktokTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/tiktok.svg')"
              alt="logo"
            />
          </template>
          <template #customWebsiteTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/website.svg')"
              alt="logo"
            />
          </template>
        </a-table>
      </div>
    </div>
  </a-card>
</template>

<script>
import { toRefs, watch, ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';

import VChart from 'vue-echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { use } from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import NoResult from '@/components/Error/NoResult.vue';

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

import api from '@/services/api';
import helper from '@/services/helper';

export default {
  components: {
    VChart,
    NoResult,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filterResult: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const chartLoading = ref(true);
    const showTable = ref(false);

    const allSrcList = api.getAllSource();
    const selectedSourceMobile = ref(allSrcList[0]);
    const srcOpt = allSrcList.map((src) => {
      return {
        label: src === 'twitter' ? 'X (Twitter)' : _.capitalize(src),
        value: src,
      };
    });
    const sourceOptions = ref(srcOpt);
    const selectedSource = ref([...allSrcList]);
    const onSourceSelect = () => {
      init(true);
    };

    const store = useStore();
    const categoryNameMap = computed(
      () => store.getters['config/defaultCategoryVisibleMap']
    );

    const { filterResult, mode } = toRefs(props);
    const coChart = ref(null);
    // const store = useStore();

    const chartOptions = reactive({
      yAxis: { type: 'value', name: '' },
      xAxis: {
        type: 'category',
        name: 'Channel',
        axisTick: { show: false },
        data: selectedSource.value,
      },
      legend: {
        type: 'scroll',
        bottom: 20,
        left: 20,
        right: 20,
      },
      series: [],
      colors: [],
      tooltip: {},
    });

    const finalEngageSeries = ref([]);
    const finalCountSeries = ref([]);
    const finalViewSeries = ref([]);

    const noData = computed(() => {
      return (
        finalEngageSeries.value.length === 0 ||
        finalCountSeries.value.length === 0
      );
    });

    const init = async (isSetOption, isForceUpdate) => {
      chartLoading.value = true;
      const engageSeriesData = {};
      const countSeriesData = {};
      const viewSeriesData = {};
      let countData = 0;
      if (filterResult.value && filterResult.value.filterData) {
        const { filterData, categoryList, level } = filterResult.value;
        // console.log('UMMMMM', filterData, categoryList, level);

        let c = categoryList;
        if (c.length === 0) {
          c = categoryNameMap.value[level];
        }

        let sources = isMobileScreen.value
          ? sourceOptions.value.filter(
              (v) => selectedSourceMobile.value === v.value
            )
          : sourceOptions.value.filter((v) =>
              selectedSource.value.includes(v.value)
            );
        for (let sIndex in sources) {
          const s = sources[sIndex];
          const finalCriteria = {
            ...filterData,
            source: [s.value],
          };
          const result = await api
            .getCategoryHistoryGraph(finalCriteria, { level })
            .catch((err) => {
              console.error('History api call error', err);
            });

          if (
            result &&
            result.message &&
            result.message.graphData &&
            Object.keys(result.message.graphData).length > 0
          ) {
            countData += 1;
            // Prepare eChart scatter
            if (c) {
              for (let categoryName of c) {
                const categoryObj = result.message.graphData[categoryName];
                // Todo check visible?
                // Calculate total engage as y, count as x
                let totalEngage = 0;
                let totalCount = 0;
                let totalView = 0;
                if (categoryObj) {
                  for (let i of categoryObj.data) {
                    totalCount += i.y.count;
                    totalEngage += i.y.engagement;
                    totalView += i.y.view;
                  }
                }

                // total count or engage of category per source
                if (!engageSeriesData[categoryName])
                  engageSeriesData[categoryName] = [0, 0, 0, 0, 0];
                engageSeriesData[categoryName][sIndex] = totalEngage;

                if (!countSeriesData[categoryName])
                  countSeriesData[categoryName] = [0, 0, 0, 0, 0];
                countSeriesData[categoryName][sIndex] = totalCount;

                if (!viewSeriesData[categoryName])
                  viewSeriesData[categoryName] = [0, 0, 0, 0, 0];
                viewSeriesData[categoryName][sIndex] = totalView;
              }
            }
          }
        }

        if (countData === 0) {
          finalEngageSeries.value = [];
          finalCountSeries.value = [];
          finalViewSeries.value = [];
        } else {
          // Prepare Engage
          let allKeys;
          let eData = [];
          allKeys = Object.keys(engageSeriesData);

          for (let catIndex in allKeys) {
            const categoryName = allKeys[catIndex];
            const colorIndex = parseInt(catIndex, 10);

            eData.push({
              type: 'bar',
              name: categoryName,
              data: engageSeriesData[categoryName],
              // color: helper.getColor(categoryName, colorIndex),
              color: helper.getColorByCategory(level, categoryName, colorIndex),
            });
          }
          finalEngageSeries.value = eData;
          // console.log(finalEngageSeries.value);

          // Prepare count
          let cData = [];
          allKeys = Object.keys(countSeriesData);
          for (let catIndex in allKeys) {
            const categoryName = allKeys[catIndex];
            const colorIndex = parseInt(catIndex, 10);
            cData.push({
              type: 'bar',
              name: categoryName,
              data: countSeriesData[categoryName],
              color: helper.getColorByCategory(level, categoryName, colorIndex),
            });
          }
          finalCountSeries.value = cData;
          chartOptions.xAxis = {
            type: 'category',
            name: 'Channel',
            axisTick: { show: false },
            data: sources,
            // data: selectedSource.value,
          };

          // Prepare view
          let vData = [];
          allKeys = Object.keys(viewSeriesData);
          for (let catIndex in allKeys) {
            const categoryName = allKeys[catIndex];
            const colorIndex = parseInt(catIndex, 10);
            vData.push({
              type: 'bar',
              name: categoryName,
              data: viewSeriesData[categoryName],
              color: helper.getColorByCategory(level, categoryName, colorIndex),
            });
          }
          finalViewSeries.value = vData;
          chartOptions.xAxis = {
            type: 'category',
            name: 'Channel',
            axisTick: { show: false },
            data: sources,
            // data: selectedSource.value,
          };

          if (mode.value === 'engagement') {
            chartOptions.yAxis.name = 'Engagement';
            chartOptions.series = finalEngageSeries.value.sort((a, b) => {
              if (a.name === 'others') {
                return 1;
              } else if (b.name === 'others') {
                return -1;
              } else {
                return 0;
              }
            });
          } else if (mode.value === 'view') {
            chartOptions.yAxis.name = 'View';
            chartOptions.series = finalViewSeries.value.sort((a, b) => {
              if (a.name === 'others') {
                return 1;
              } else if (b.name === 'others') {
                return -1;
              } else {
                return 0;
              }
            });
          } else {
            chartOptions.yAxis.name = 'Mentions';
            chartOptions.series = finalCountSeries.value.sort((a, b) => {
              if (a.name === 'others') {
                return 1;
              } else if (b.name === 'others') {
                return -1;
              } else {
                return 0;
              }
            });
          }
          if (isSetOption) {
            coChart.value.setOption(chartOptions, { notMerge: true });
          }
          if (isForceUpdate) {
            forceUpdateSeries();
          }
        }
      }
      chartLoading.value = false;
    };

    const forceUpdateSeries = () => {
      if (mode.value === 'engagement') {
        coChart.value.setOption(
          {
            yAxis: {
              type: 'value',
              name: 'Engagement',
            },
            xAxis: {
              type: 'category',
              name: 'Channel',
              axisTick: { show: false },
              data: selectedSource.value,
            },
            series: finalEngageSeries.value.sort((a, b) => {
              if (a.name === 'others') {
                return 1;
              } else if (b.name === 'others') {
                return -1;
              } else {
                return 0;
              }
            }),
          },
          { replaceMerge: ['yAxis', 'xAxis', 'series'] }
        );
        // chartOptions.yAxis.name = 'engagement';
        // chartOptions.series = finalEngageSeries.value;
      } else if (mode.value === 'view') {
        coChart.value.setOption(
          {
            yAxis: {
              type: 'value',
              name: 'View',
            },
            xAxis: {
              type: 'category',
              name: 'Channel',
              axisTick: { show: false },
              data: selectedSource.value,
            },
            series: finalViewSeries.value.sort((a, b) => {
              if (a.name === 'others') {
                return 1;
              } else if (b.name === 'others') {
                return -1;
              } else {
                return 0;
              }
            }),
          },
          { replaceMerge: ['yAxis', 'xAxis', 'series'] }
        );
        // chartOptions.yAxis.name = 'engagement';
        // chartOptions.series = finalEngageSeries.value;
      } else {
        coChart.value.setOption(
          {
            yAxis: {
              type: 'value',
              name: 'Mentions',
            },
            xAxis: {
              type: 'category',
              name: 'Channel',
              axisTick: { show: false },
              data: selectedSource.value,
            },
            series: finalCountSeries.value.sort((a, b) => {
              if (a.name === 'others') {
                return 1;
              } else if (b.name === 'others') {
                return -1;
              } else {
                return 0;
              }
            }),
          },
          { replaceMerge: ['yAxis', 'xAxis', 'series'] }
        );
        // chartOptions.yAxis.name = 'mentions';
        // chartOptions.series = finalCountSeries.value;
      }
    };

    const onChartClick = (e) => {
      const { filterData, level } = filterResult.value;
      const newFilter = JSON.parse(JSON.stringify(filterData));
      const source = [e.name];
      const category = [e.seriesName]; // selected

      const filter = {
        payload: {
          title: `Data of "${e.seriesName}" category in "${e.name}" channel`,
        },
        criteria: {
          source,
          category: newFilter.category,
          subCategory: newFilter.subCategory,
          time: newFilter.time,
          sort: {
            direction: 'desc',
            type: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      if (level > 0) {
        for (let i in filter.criteria['subCategory']) {
          if (filter.criteria['subCategory'][i].level == level) {
            filter.criteria['subCategory'][i].category = category;
          }
        }
      } else {
        filter.criteria['category'] = category;
      }
      // if (level === 0) {
      //   filter.criteria.category = category;
      // } else {
      //   filter.criteria.subCategory = [
      //     {
      //       level,
      //       category,
      //     },
      //   ];
      // }
      store.dispatch('message/showMessageModal', filter);
    };

    watch(filterResult.value, () => {
      const srcList = filterResult.value.filterData.source;
      // console.log('WTF', filterResult.value.level);

      if (srcList) {
        if (srcList.length > 0) {
          sourceOptions.value = srcList.map((src) => {
            return {
              label: src === 'twitter' ? 'X (Twitter)' : _.capitalize(src),
              value: src,
            };
          });
          selectedSource.value = [...srcList];
        } else if (srcList.length === 0) {
          selectedSource.value = allSrcList.map((v) => v);
        }

        init(true, true);
        initSOVTable();
      }
    });

    watch(mode, () => {
      // if has data just change active mode
      initSOVTable();
      if (isMobileScreen.value) {
        init();
      } else {
        forceUpdateSeries();
      }
    });

    watch(isMobileScreen, () => {
      if (!isMobileScreen.value) {
        selectedSource.value = [...allSrcList];
      } else {
        selectedSourceMobile.value = allSrcList[0];
      }
      init();
    });

    onMounted(() => {
      setTimeout(() => {
        init();
        initSOVTable();
      }, 200);
    });

    // TABLE SOV
    const channelData = ref([]);
    const channelDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    const channelColumns = [
      {
        // title: 'Share of Voice (SOV)',
        dataIndex: 'category',
        key: 'category',
        fixed: 'left',
        // width: 250,
        slots: { title: 'customCategoryTitle', customRender: 'category' },
      },
      {
        // title: 'Facebook',
        dataIndex: 'facebook',
        key: 'facebook',
        align: 'center',
        slots: { title: 'customFacebookTitle' },
        customRender: ({ text, record }) => {
          if (record && record.facebook.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Twitter',
        dataIndex: 'twitter',
        key: 'twitter',
        align: 'center',
        slots: { title: 'customTwitterTitle', customRender: 'twitter' },
        customRender: ({ text, record }) => {
          if (record && record.twitter.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Instagram',
        dataIndex: 'instagram',
        key: 'instagram',
        align: 'center',
        slots: { title: 'customInstagramTitle', customRender: 'instagram' },
        customRender: ({ text, record }) => {
          if (record && record.instagram.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Youtube',
        dataIndex: 'youtube',
        key: 'youtube',
        align: 'center',
        slots: { title: 'customYoutubeTitle', customRender: 'youtube' },
        customRender: ({ text, record }) => {
          if (record && record.youtube.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Pantip',
        dataIndex: 'pantip',
        key: 'pantip',
        align: 'center',
        slots: { title: 'customPantipTitle', customRender: 'pantip' },
        customRender: ({ text, record }) => {
          if (record && record.pantip.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Blockdit',
        dataIndex: 'blockdit',
        key: 'blockdit',
        align: 'center',
        slots: { title: 'customBlockditTitle', customRender: 'blockdit' },
        customRender: ({ text, record }) => {
          if (record && record.blockdit.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Tiktok',
        dataIndex: 'tiktok',
        key: 'tiktok',
        align: 'center',
        slots: { title: 'customTiktokTitle', customRender: 'tiktok' },
        customRender: ({ text, record }) => {
          if (record && record.tiktok.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
      {
        // title: 'Website',
        dataIndex: 'website',
        slots: { title: 'customWebsiteTitle', customRender: 'website' },
        key: 'website',
        align: 'center',
        customRender: ({ text, record }) => {
          if (record && record.website.isHighest) {
            return {
              children: convertValue(text.value),
              props: {
                style: { background: '#def7ef' },
              },
            };
          }
          return {
            children: convertValue(text.value),
          };
        },
      },
    ];

    const initSOVTable = async () => {
      // TODO : using api
      if (filterResult.value && filterResult.value.filterData) {
        const { filterData, categoryList, level } = filterResult.value;
        let c = categoryList;
        if (c.length === 0) {
          c = categoryNameMap.value[level];
        }

        if (filterResult.value.valid) {
          const result = await api.getCategorySOV(filterData, level);
          if (result.message.data) {
            const countList = [];
            const engageList = [];
            const viewList = [];
            let obj;
            for (let categoryKey in result.message.data) {
              if (mode.value === 'engagement') {
                obj = {
                  category: result.message.data[categoryKey].category,
                  facebook: {
                    value:
                      result.message.data[categoryKey]['facebook'].engagement,
                    isHighest:
                      result.message.data[categoryKey]['facebook']
                        .highestEngagement,
                  },
                  twitter: {
                    value:
                      result.message.data[categoryKey]['twitter'].engagement,
                    isHighest:
                      result.message.data[categoryKey]['twitter']
                        .highestEngagement,
                  },
                  instagram: {
                    value:
                      result.message.data[categoryKey]['instagram'].engagement,
                    isHighest:
                      result.message.data[categoryKey]['instagram']
                        .highestEngagement,
                  },
                  youtube: {
                    value:
                      result.message.data[categoryKey]['youtube'].engagement,
                    isHighest:
                      result.message.data[categoryKey]['youtube']
                        .highestEngagement,
                  },
                  pantip: {
                    value:
                      result.message.data[categoryKey]['pantip'].engagement,
                    isHighest:
                      result.message.data[categoryKey]['pantip']
                        .highestEngagement,
                  },
                  blockdit: {
                    value:
                      result.message.data[categoryKey]['blockdit'].engagement,
                    isHighest:
                      result.message.data[categoryKey]['blockdit']
                        .highestEngagement,
                  },
                  tiktok: {
                    value:
                      result.message.data[categoryKey]['tiktok'].engagement,
                    isHighest:
                      result.message.data[categoryKey]['tiktok']
                        .highestEngagement,
                  },
                  website: {
                    value:
                      result.message.data[categoryKey]['website'].engagement,
                    isHighest:
                      result.message.data[categoryKey]['website']
                        .highestEngagement,
                  },
                };
                engageList.push(obj);
              } else if (mode.value === 'mentioned') {
                obj = {
                  category: result.message.data[categoryKey].category,
                  facebook: {
                    value: result.message.data[categoryKey]['facebook'].mention,
                    isHighest:
                      result.message.data[categoryKey]['facebook']
                        .highestMention,
                  },
                  twitter: {
                    value: result.message.data[categoryKey]['twitter'].mention,
                    isHighest:
                      result.message.data[categoryKey]['twitter']
                        .highestMention,
                  },
                  instagram: {
                    value:
                      result.message.data[categoryKey]['instagram'].mention,
                    isHighest:
                      result.message.data[categoryKey]['instagram']
                        .highestMention,
                  },
                  youtube: {
                    value: result.message.data[categoryKey]['youtube'].mention,
                    isHighest:
                      result.message.data[categoryKey]['youtube']
                        .highestMention,
                  },
                  pantip: {
                    value: result.message.data[categoryKey]['pantip'].mention,
                    isHighest:
                      result.message.data[categoryKey]['pantip'].highestMention,
                  },
                  blockdit: {
                    value: result.message.data[categoryKey]['blockdit'].mention,
                    isHighest:
                      result.message.data[categoryKey]['blockdit']
                        .highestMention,
                  },
                  tiktok: {
                    value: result.message.data[categoryKey]['tiktok'].mention,
                    isHighest:
                      result.message.data[categoryKey]['tiktok'].highestMention,
                  },
                  website: {
                    value: result.message.data[categoryKey]['website'].mention,
                    isHighest:
                      result.message.data[categoryKey]['website']
                        .highestMention,
                  },
                };
                countList.push(obj);
              } else if (mode.value === 'view') {
                obj = {
                  category: result.message.data[categoryKey].category,
                  facebook: {
                    value: result.message.data[categoryKey]['facebook'].view,
                    isHighest:
                      result.message.data[categoryKey]['facebook'].highestView,
                  },
                  twitter: {
                    value: result.message.data[categoryKey]['twitter'].view,
                    isHighest:
                      result.message.data[categoryKey]['twitter'].highestView,
                  },
                  instagram: {
                    value: result.message.data[categoryKey]['instagram'].view,
                    isHighest:
                      result.message.data[categoryKey]['instagram'].highestView,
                  },
                  youtube: {
                    value: result.message.data[categoryKey]['youtube'].view,
                    isHighest:
                      result.message.data[categoryKey]['youtube'].highestView,
                  },
                  pantip: {
                    value: result.message.data[categoryKey]['pantip'].view,
                    isHighest:
                      result.message.data[categoryKey]['pantip'].highestView,
                  },
                  blockdit: {
                    value: result.message.data[categoryKey]['blockdit'].view,
                    isHighest:
                      result.message.data[categoryKey]['blockdit'].highestView,
                  },
                  tiktok: {
                    value: result.message.data[categoryKey]['tiktok'].view,
                    isHighest:
                      result.message.data[categoryKey]['tiktok'].highestView,
                  },
                  website: {
                    value: result.message.data[categoryKey]['website'].view,
                    isHighest:
                      result.message.data[categoryKey]['website'].highestView,
                  },
                };
                viewList.push(obj);
              }
            }
            channelDataByMode.engagement = engageList;
            channelDataByMode.mentioned = countList;
            channelDataByMode.view = viewList;

            channelData.value = channelDataByMode[mode.value].sort((a, b) => {
              if (a.category === 'others') {
                return 1;
              } else if (b.category === 'others') {
                return -1;
              } else {
                return 0;
              }
            });
          }
        }
      }
    };

    const getColor = (catKey, index) => {
      return helper.getColorByCategory(filterResult.value.level, catKey, index);
    };

    const convertValue = (value) => {
      if (value) {
        return helper.numeral(value);
      }
      return 0;
    };

    const changeStateTable = () => {
      if (!noData.value) {
        showTable.value = !showTable.value;
      }
    };

    return {
      chartLoading,
      chartOptions,
      coChart,
      onChartClick,
      sourceOptions,
      selectedSource,
      onSourceSelect,
      finalEngageSeries,
      finalCountSeries,
      isMobileScreen,
      channelData,
      getColor,
      convertValue,
      showTable,
      channelColumns,
      noData,
      changeStateTable,
      selectedSourceMobile,
    };
  },
};
</script>

<style lang="scss">
.channel-overview {
  .load-wrapper {
    min-height: 400px;
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .chart-wrapper {
    width: 100%;
    min-height: 400px;
    .chart {
      height: 400px;
    }
  }
  .source-selector {
    padding: 24px;
  }
  .source-selector-mobile {
    .source-select-title {
      font-weight: 500;
      line-height: 24px;
      color: #272b41;
      margin-bottom: 5px;
    }
    .source-select {
      .ant-select-selector {
        border: 1px solid #e3e6ef;
        border-radius: 4px;
        min-height: 42px !important;
        .ant-select-selection-search input {
          height: 42px;
        }
        .ant-select-selection-placeholder {
          line-height: 40px !important;
        }
      }
      .source-item {
        display: flex;
        align-items: center;
        .source-img {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
  .no-data-wrapper {
    min-height: 400px;
    padding: 20px;
  }
  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .expand-sov-table {
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 0;
    .expand-text {
      color: #097cff;
      font-size: 12px;
    }
  }
  // TABLE CSS
  .channel-table-wrapper {
    padding: 24px;
    .channel-table {
      .ant-table-thead > tr > th {
        background: #fff !important;
        border-top: 1px solid #f0f0f0 !important;
      }

      .ant-table-thead > tr > th:first-child {
        border-left: 1px solid #f0f0f0 !important;
      }

      .ant-table-thead > tr > th:last-child {
        border-right: 1px solid #f0f0f0 !important;
      }

      .ant-table tbody > tr > td:first-child {
        border-left: 1px solid #f1f2f6;
      }

      .category-title {
        font-weight: 700;
        font-size: 16px;
        color: #5a5f7d;
      }

      .badge-wrapper {
        .category-text {
          color: #5a5f7d;
          text-transform: capitalize;
        }
      }

      .source-value {
        &.highlight-color {
          background: #fff;
          // background: #def7ef;
        }
      }

      .category-data {
        color: #5a5f7d;
      }
    }
  }

  .disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
</style>
